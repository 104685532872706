import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the styles
import building2 from './assets/girlsHostel/building2.jpeg';
import building1 from './assets/girlsHostel/building1.jpeg';
import building3 from './assets/girlsHostel/building3.jpeg';
import buildingFront from './assets/girlsHostel/front2.jpeg';
import room1 from './assets/girlsHostel/room1.jpeg';
import room2 from './assets/girlsHostel/room2.jpeg';
import room3 from './assets/girlsHostel/room3.jpeg';
import room4 from './assets/girlsHostel/room5.jpeg';
import room5 from './assets/girlsHostel/room6.jpeg';
import room6 from './assets/girlsHostel/room7.jpeg';
import lobby from './assets/girlsHostel/lobby.jpeg';
import washing1 from './assets/girlsHostel/washing1.jpg';


const Hostel1Page: React.FC = () => {
    const images = [
        { original: building2 },
        { original: building1 },
        { original: building3 },
        { original: room1 },
        { original: room2 },
        { original: room3 },
        { original: room4 },
        { original: room5 },
        { original: room6 },
        { original: room1 },
        { original: room2 },
        { original: room3 },
        { original: room5 },
        { original: lobby },
        { original: washing1 },
      ];
  return (
    <div>
    <div className=" h-full py-20 w-full bg-black">
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} autoPlay={true} />
    </div>
  </div>
  )
}

export default Hostel1Page;
