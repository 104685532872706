import React from 'react';
import Slider from 'react-slick';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the styles
import outdoorImage from './assets/boysHostel/outdoor2.jpg';
import outdoorImage2 from './assets/boysHostel/outdoor4.jpg';
import outdoorImage3 from './assets/boysHostel/outdoor6.jpg';
import roof1 from './assets/boysHostel/roof1.jpg';
import roof2 from './assets/boysHostel/roof2.jpg';
import roof3 from './assets/boysHostel/roof3.jpg';
import interior1 from './assets/boysHostel/interior1.jpg';
import interior2 from './assets/boysHostel/interior2.jpg';
import room1 from './assets/boysHostel/room1.jpg';
import room2 from './assets/boysHostel/room2.jpg';
import room3 from './assets/boysHostel/room3.jpg';
import room5 from './assets/boysHostel/room5.jpg';
import lobby from './assets/boysHostel/lobby1.jpg';
import washing2 from './assets/girlsHostel/washing2.jpg';


const Hostel2Page: React.FC = () => {
    const images = [
        { original: outdoorImage2 },
        { original: outdoorImage },
        { original: outdoorImage3 },
        { original: roof1 },
        { original: roof2 },
        { original: roof3 },
        { original: interior1 },
        { original: interior2 },
        { original: room1 },
        { original: room2 },
        { original: room3 },
        { original: room5 },
        { original: lobby },
        { original: washing2 },
      ];
    return (
        <div>
        <div className=" h-full py-20 w-full bg-black">
          <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} autoPlay={true} />
        </div>
      </div>
    );
  };

export default Hostel2Page;
