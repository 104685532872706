import React from 'react';
import HostelsPage from './HostelsPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Hostel1Page from './hostel1Page'; // Import the Hostel1Page component
import Hostel2Page from './Hostel2Page'; // Import the Hostel2Page component
import ScrollToTop from './ScrollToTop';
import { Layout } from './Layout';

const App: React.FC = () => {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
    <Route path="/" element={<Layout />}>
        <Route index element={<HostelsPage />} />
        <Route path="hostel1" element={<Hostel1Page />} />
        <Route path="hostel2" element={<Hostel2Page />} />
      </Route>
    </Routes>
  </BrowserRouter>
          </>
  );
}

export default App;