import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

export const Layout = () => {
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: any) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}