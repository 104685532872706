import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import darshHostelLogo from './assets/darshHabitatLogo.jpeg';

const Header: React.FC = () => {
  const controls = useAnimation();
  const navigate = useNavigate();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    });
  }, [controls]);

  return (
    <header className="overflow-hidden z-[100] top-0 bg-gradient-to-r from-dark to-primary text-white w-full p-4 flex gap-4 md:gap-10 flex-row justify-center items-center shadow-md">
     <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex gap-5 items-center mb-4 md:mb-0 cursor-pointer text-light"
        onClick={()=>{navigate('/')}}
      ><hr className='h-1/2 w-20'/></motion.div>
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex md:flex-row flex-col gap-3 items-center  md:mb-0 cursor-pointer text-center"
        onClick={()=>{navigate('/')}}
      >
        <motion.div 
          className="h-10 w-20 mr-2 rounded-lg flex items-center justify-center bg-white text-secondary"
          whileHover={{ scale: 1.1 }}
        >
          <img 
            src={darshHostelLogo} 
            alt="Logo" 
            className="h-8"
          />
        </motion.div>
        <motion.h1 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-lg md:text-xl font-bold"
        >
          Darsh Hostels
        </motion.h1>
      </motion.div>
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex gap-5 items-center mb-4 md:mb-0 cursor-pointer text-light"
        onClick={()=>{navigate('/')}}
      ><hr className='h-1/2 w-20'/></motion.div>
      {/* <nav className="flex gap-4">
        <motion.button 
          initial={{ opacity: 0, y: -10 }}
          animate={controls}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="text-accent text-sm font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-gray-500 focus:text-white"
        >
          Contact
        </motion.button>
        <motion.button 
          initial={{ opacity: 0, y: -10 }}
          animate={controls}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="text-accent text-sm font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-gray-500 focus:text-white"
        >
          About Us
        </motion.button>
        <motion.button 
          initial={{ opacity: 0, y: -10 }}
          animate={controls}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="text-accent font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-gray-500 focus:text-white"
        >
          Rooms & Rates
        </motion.button>
      </nav> */}
    </header>
  );
};

export default Header;
