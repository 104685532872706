import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="py-8 w-full h-[50vh] bg-black text-white text-center flex flex-col lg:flex-row items-center justify-between">
      <div className="lg:w-1/2 h-full px-4 gap-5 flex flex-col items-center justify-center border-y-2 border-light">
        <p className="font-semibold lg:w-4/5">
          <p className="text-accent font-bold">Address:</p> PDPU Rd, nr. PDPU Highrise Hostel, opp. International
          Automobile Center of Excellence, Raysan, Gujarat 382007
        </p>
        <p> <p className="text-accent font-bold">Contact:</p>+91 6355-618638</p>
      </div>
      <div className="w-4/5 lg:w-1/2 h-full px-4 mt-4 lg:mt-0 flex items-center justify-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14673.586352310262!2d72.6718665!3d23.1557213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e81f0691a2adb%3A0xe089300e44c18af5!2sDarsh%20Habitat!5e0!3m2!1sen!2sin!4v1717491145471!5m2!1sen!2sin"
          width="80%"
          height="100%"
          style={{ border: 0, borderRadius: "15px" }}
          allowFullScreen={false}
          loading="lazy"
        ></iframe>
      </div>
    </footer>
  );
};

export default Footer;
