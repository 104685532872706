import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import amenities from "./assets/amenities.png";
import mess from "./assets/canteen.png";
import fiveStarLogo from "./assets/five-stars.png";
import home from "./assets/home.png";
import beds from "./assets/twin-beds.png";
import pdpuLogo from "./assets/pdpu.png";
import giftCityLogo from "./assets/giftCityLocation.png";
import hostelLogo from "./assets/hostelLogo.png";
import hostel1Image from "./assets/girlsHostel/building4.jpeg";
import hostel2Image from "./assets/boysHostel/buildingLook.jpeg";
import liveKitchen from "./assets/livekitchen.png";
import match from "./assets/match.jpg";
import ganpatiVisarjan1 from "./assets/ganpati visarjan.jpg";
import ganpatiVisarjan2 from "./assets/ganpati visarjan2.jpg";
import Poster from "./assets/girlsHostel/nameFront.jpeg";
import Poster2 from "./assets/girlsHostel/nameFront2.jpeg";

const HostelsPage: React.FC = () => {
  const navigate = useNavigate();
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    });
  }, [controls]);

  const hostelsDetails = [
    { data: "For professionals & students", logo: fiveStarLogo },
    { data: "Multiple Amenities", logo: amenities },
  ];

  const cardsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (cardsRef.current) {
      observer.observe(cardsRef.current);
    }

    return () => {
      if (cardsRef.current) {
        observer.unobserve(cardsRef.current);
      }
    };
  }, []);

  return (
    <div className="font-sans bg-gradient-to-b from-primary to-dark min-h-screen flex flex-col items-center">
      <div
        className="w-full h-screen bg-cover bg-center relative"
        style={{ backgroundImage: `url(${Poster2})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center pt-40">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-2xl font-bold text-transparent bg-gradient-to-r from-white to-light bg-clip-text text-center"
          >
            Experience Exceptional Living: <br /> Elevating Student and
            Professional Accommodation to Unmatched Comfort and Luxury!
          </motion.h1>
        </div>
      </div>

      <header className="my-20 px-4 text-center">
        <motion.h1
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-3xl font-bold text-accent"
        >
          Our Hostels
        </motion.h1>
      </header>

      <div className="flex flex-col lg:flex-row w-full h-fit lg:h-[80vh] lg:max-h-[700px] justify-center items-center gap-20 lg:gap-8 mb-12 p-4">
        <div className="cursor-pointer w-full lg:w-1/2 h-full flex flex-col gap-8 justify-center items-center relative ">
          <motion.h1
            initial={{ opacity: 0, y: -10 }}
            animate={controls}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="text-xl font-extrabold text-textBeige text-center uppercase"
            onClick={() => navigate("hostel1")}
          >
            Darsh Habitat-1 <br />( For Girls )
          </motion.h1>
          <div className=" relative w-full lg:w-4/5 h-[60vh] group">
            <img
              src={hostel1Image}
              alt="Hostel 1"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
            <div
              onClick={() => navigate("hostel1")}
              className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity"
            >
              Click For More Details
            </div>
          </div>
        </div>
        <div className="cursor-pointer w-full lg:w-1/2 h-full flex flex-col gap-5 justify-center items-center relative ">
          <motion.h1
            initial={{ opacity: 0, y: -10 }}
            animate={controls}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("hostel2")}
            className="text-xl font-extrabold text-textBeige text-center uppercase"
          >
            Darsh Habitat-2 <br />( For Boys )
          </motion.h1>
          <div className="relative w-full lg:w-4/5 h-[60vh] group">
            <img
              src={hostel2Image}
              alt="Hostel 2"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
            <div
              onClick={() => navigate("hostel2")}
              className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity"
            >
              Click For More Details
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto text-center py-8 border-t-2 border-accent">
        <h2 className="text-2xl font-semibold mb-4 text-accent">
          Hostel Details
        </h2>
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-20 place-items-center justify-between "
          ref={cardsRef}
        >
          {hostelsDetails.map((item, index) => (
            <motion.div
              key={index}
              className="w-4/5 md:w-full p-4  flex flex-col items-center gap-4 rounded-lg shadow-md overflow-hidden  transition-all duration-300"
            >
              <img
                src={item.logo}
                alt={item.data}
                className="w-20 h-20 object-cover rounded-t-lg iconFilter"
              />
              <div className="p-4 flex-grow">
                <h1 className="text-xl font-semibold text-textBeige">
                  {item.data.split("\n")[0]}
                </h1>
                <p className="text-gray-700">
                  {item.data.split("\n").slice(1).join("\n")}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-20 flex lg:flex-row items-center justify-center md:p-4 gap-2">
          {/* PDPU */}
          <div className="flex flex-col items-center">
            <p className="text-center text-textBeige font-bold">PDPU</p>
            <img
              src={pdpuLogo}
              alt="PDPU"
              className="w-8 h-8 md:w-20 md:h-20 mb-2 iconFilter2"
            />
          </div>

          {/* HR with Distance Text */}
          <div className="flex items-center justify-center w-full lg:w-auto lg:mx-4 my-2 lg:my-0">
            <hr className="border-text-textBeige w-full lg:w-24" />
            <p className="mx-2 text-light">200 m</p>
            <hr className="border-text-textBeige w-full lg:w-24" />
          </div>

          {/* Hostel */}
          <div className="flex flex-col items-center">
            <p className="text-center text-textBeige font-bold">Our Hostel</p>
            <img
              src={hostelLogo}
              alt="Hostel"
              className="w-8 h-8 md:w-20 md:h-20 mb-2 iconFilter2"
            />
          </div>

          {/* HR with Distance Text */}
          <div className="flex items-center justify-center w-full lg:w-auto lg:mx-4 my-2 lg:my-0">
            <hr className="border-text-textBeige w-full lg:w-24" />
            <p className="mx-2 text-light">1 km</p>
            <hr className="border-text-textBeige w-full lg:w-24" />
          </div>

          {/* Gift City */}
          <div className="flex flex-col items-center">
            <p className="text-center text-textBeige font-bold">Gift City</p>
            <img
              src={giftCityLogo}
              alt="Gift City"
              className="w-8 h-8 md:w-20 md:h-20 mb-2 iconFilter2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full h-fit lg:h-[80vh] lg:max-h-[700px] justify-center items-center gap-20 lg:gap-8 my-12 p-4 bg-gradient-to-r from-white to-gray-300">
        <div className="w-full lg:w-1/2 h-full flex justify-center items-center">
          <img
            src={liveKitchen}
            alt="Live Kitchen"
            className="w-full lg:w-3/4 h-[60vh] object-cover rounded-lg shadow-lg"
          />
        </div>
        <div className="w-full lg:w-1/2 h-full flex flex-col justify-center items-start p-4 gap-4">
          <h2 className="text-2xl font-semibold text-light">Live Kitchen</h2>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Fresh and hygienic meals prepared daily</li>
            <li>Variety of cuisines to cater to different tastes</li>
            <li>Professional chefs ensuring high quality food</li>
            <li>
              Use of branded Products to ensure safety and consistent
              reliability
            </li>
            <li>Open kitchen for transparency and trust</li>
            <li>Pure vegitarian food</li>
            <li>Jain food available before sunset( on demand )</li>
          </ul>
        </div>
      </div>
      <div className="max-w-[80%] mx-auto text-center py-32 border-t-2 border-accent">
        <h2 className="text-2xl font-semibold mb-4 text-black">About Us</h2>
        <div
          className="flex gap-4 mt-20 place-items-center justify-between "
          ref={cardsRef}
        >
          <p className="w-full text-black ">
            Darsh Habitat is more than just a regular hostel.It's a special
            place that feels like a second home for students. With its safe and
            secure surroundings near the Sabarmati River, it offers a natural
            environment that nurtures students' lives. It provides all the
            comforts and facilities of home, making it a great place for
            students to live and learn.
          </p>
        </div>
      </div>
      <div className="max-w-[80%] mx-auto text-center py-20 border-t-2 border-accent">
        <h2 className="text-2xl font-semibold mb-4 text-light">
          Recent Events
        </h2>
        <div
          className="flex flex-col lg:flex-row gap-4 mt-20 place-items-center justify-between "
          ref={cardsRef}
        >
          <div className="w-full lg:w-1/2 h-full rounded-lg shadow-lg ">
            <img src={match} alt="" />
          </div>
          <div className="w-full lg:w-1/2 h-full rounded-lg shadow-lg ">
            <img src={ganpatiVisarjan1} alt="" />
          </div>
          <div className="w-full lg:w-1/2 h-full rounded-lg shadow-lg ">
            <img src={ganpatiVisarjan2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelsPage;
